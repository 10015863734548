var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_vm._m(0),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-3 box-dashboard-data d-flex flex-column justify-space-between",attrs:{"elevation":"1"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('strong',[_vm._v("รายชื่อผู้รอรับบริการ")]),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("เพิ่มเติม >>>")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataListCustomer,"search":_vm.filter_search,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'}},[_vm._v("mdi-circle ")])],1),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.customer_code))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(("คุณ" + (item.customer_fnameth) + " " + (item.customer_lnameth))))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(((item.customer_activity) + " " + (item.customer_activity_list))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_prepaid))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_activity_list_cost - item.customer_prepaid))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-3 box-dashboard-data d-flex flex-column justify-space-between",attrs:{"elevation":"1"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('strong',[_vm._v("รายชื่อผู้รอปรึกษาแพทย์")]),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("เพิ่มเติม >>>")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataListCustomer,"search":_vm.filter_search,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'}},[_vm._v("mdi-circle ")])],1),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.customer_code))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(("คุณ" + (item.customer_fnameth) + " " + (item.customer_lnameth))))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(((item.customer_activity) + " " + (item.customer_activity_list))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_prepaid))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_activity_list_cost - item.customer_prepaid))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-3 box-dashboard-data d-flex flex-column justify-space-between",attrs:{"elevation":"1"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('strong',[_vm._v("รายชื่อผู้รอทำหัตถการ")]),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("เพิ่มเติม >>>")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataListCustomer,"search":_vm.filter_search,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'}},[_vm._v("mdi-circle ")])],1),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.customer_code))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(("คุณ" + (item.customer_fnameth) + " " + (item.customer_lnameth))))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(((item.customer_activity) + " " + (item.customer_activity_list))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_prepaid))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_activity_list_cost - item.customer_prepaid))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-3 box-dashboard-data d-flex flex-column justify-space-between",attrs:{"elevation":"1"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('strong',[_vm._v("รายชื่อผู้ที่ใช้บริการเรียบร้อยแล้ว")]),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("เพิ่มเติม >>>")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataListCustomer,"search":_vm.filter_search,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'}},[_vm._v("mdi-circle ")])],1),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.customer_code))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(("คุณ" + (item.customer_fnameth) + " " + (item.customer_lnameth))))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(((item.customer_activity) + " " + (item.customer_activity_list))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_prepaid))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_activity_list_cost - item.customer_prepaid))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-card',{staticClass:"pa-3 box-dashboard-data d-flex flex-column justify-space-between",attrs:{"elevation":"1"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('strong',[_vm._v("รายชื่อลูกค้าที่ต้องโทร")]),_c('v-btn',{attrs:{"text":"","color":"primary"}},[_vm._v("เพิ่มเติม >>>")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.dataListCustomer,"search":_vm.filter_search,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":item.customer_activity === 'สนใจ' ? 'red' : item.customer_activity === 'ทำ' ? 'pink' : 'purple'}},[_vm._v("mdi-circle ")])],1),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(item.customer_code))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(("คุณ" + (item.customer_fnameth) + " " + (item.customer_lnameth))))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.moment(item.customer_birthdate, "YYYYMMDD").locale('th').fromNow().replace(' ปีที่แล้ว', '')))]),_c('td',{staticClass:"text-start"},[_vm._v(_vm._s(((item.customer_activity) + " " + (item.customer_activity_list))))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_prepaid))]),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(item.customer_activity_list_cost - item.customer_prepaid))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("Dashboard")])])}]

export { render, staticRenderFns }